import { Link } from 'react-router-dom';
import { Element, Link as ScrollLink } from 'react-scroll';
const B2B = () => {
    const handleSubmit = (event) => {
        alert("전송되었습니다. 빠른 시일 내애 답변드리겠습니다. 메인 페이지로 이동합니다.");
    }

    return (
        <>
            <Element name="b2b" className="p-4 bg-ultralightmain">
                <div className="h-screen justify-center items-center flex">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-primary mb-4">제휴 제안 및 B2B</h1>
                        <p className="text-lg text-gray-500 mb-8 mx-4">제휴 관련 문의 창구입니다. 고객 지원은 <Link to="/support" className='text-primary'>이곳</Link>을 이용해 주세요.</p>
                        <form action="https://send.pageclip.co/T8zGSzHlFt6MREG8wIPebH6LEshQFAhX/goding-proposals" method="post" className="pageclip-form flex flex-col items-center" onSubmit={handleSubmit}>
                            <input type="text" name="name" placeholder="성함" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required />
                            <select name="category" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required>
                                <option value="">문의 유형</option>
                                <option value="제휴 제안">제휴 제안</option>
                                <option value="광고 문의">광고 문의</option>
                                <option value="투자 제안">투자 제안</option>
                            </select>
                            <input type="email" name="email" placeholder="이메일" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required />
                            <textarea name="message" placeholder="문의 내용" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required></textarea>
                            <button type="submit" className="w-96 p-4 bg-primary text-white font-bold text-xl rounded-md hover:bg-blue-800 transition duration-300">문의하기</button>
                        </form>
                    </div>
                </div>
            </Element>
        </>
    );
}

export default B2B;