import './App.css';
import Structures from './Structures';
import ReactGA from 'react-ga4';

function App() {

  ReactGA.initialize(`G-F1R4PHTZPX`);

  // Send pageview with a custom path
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname
  });
  return (
    <div className="App w-screen">
      <Structures />
    </div>
  );
}

export default App;
