import { Link } from 'react-router-dom';
import { Element, Link as ScrollLink } from 'react-scroll';
import { Helmet } from 'react-helmet';

const Support = () => {
    const handleSubmit = (event) => {
        alert("문의사항이 접수되었으며, 이메일로 처리 결과를 전송해 드리겠습니다. 갓잉 메인으로 이동합니다.");
    }
    return (
        <>
            <Helmet>
                <title>고객 지원 | 갓잉</title>
            </Helmet>
            <Element name="support" className="p-4 bg-ultralightmain">
                <div className="min-h-screen justify-center items-center flex">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-primary mb-4 mt-12">고객 지원</h1>
                        <p className="text-lg text-gray-500 mb-8 px-4">고객 지원 전용입니다. 제휴 문의는 <Link to="/B2B" className='text-primary'>이곳</Link>을 이용해 주세요.</p>
                        <form action="https://send.pageclip.co/T8zGSzHlFt6MREG8wIPebH6LEshQFAhX/goding-support" method="post" className="pageclip-form flex flex-col items-center" onSubmit={handleSubmit}>
                            <input type="text" name="name" placeholder="성함" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required />
                            <input type="email" name="email" placeholder="이메일" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required />
                            <input type="text" name="handle" placeholder="갓잉 닉네임 (혹은 '없음')" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required />
                            <select name="category" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required>
                                <option value="">문의 유형</option>
                                <option value="서비스 문의">서비스 문의</option>
                                <option value="계정 정보 수정">계정 정보 수정</option>
                                <option value="결제 문의">결제 문의</option>
                                <option value="버그 제보">버그 제보</option>
                                <option value="기능 제안">기능 제안</option>
                                <option value="신고">신고</option>
                                <option value="리크루팅">리크루팅</option>
                                <option value="기타 문의">기타 문의</option>
                            </select>
                            <textarea name="message" placeholder="문의 내용" className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md" required></textarea>
                            <span className="text-xs text-gray-500 mb-4">아래 버튼을 누르시면 <Link to="/privacy" className="text-primary">개인정보 처리방침</Link>에 동의하는 것으로 간주됩니다.</span>
                            <button type="submit" className="w-96 p-4 bg-primary text-white font-bold text-xl rounded-md hover:bg-blue-800 transition duration-300 mb-12">문의하기</button>
                        </form>
                    </div>
                </div>
            </Element>
        </>
    );
}

export default Support;