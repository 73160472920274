import { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import { Helmet } from 'react-helmet';

const Redeem = () => {
    const [coupon, setCoupon] = useState('');
    const [handle, setHandle] = useState('');
    const [message, setMessage] = useState('');
    const [proEndTime, setProEndTime] = useState('');

    // URL 쿼리 파라미터에서 코드와 핸들 읽기
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const codeParam = queryParams.get('serial'); // URL에서 'code' 파라미터 읽기
        const handleParam = queryParams.get('handle'); // URL에서 'handle' 파라미터 읽기

        if (codeParam) setCoupon(codeParam); // 코드 미리 채우기
        if (handleParam) setHandle(handleParam); // 핸들 미리 채우기
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(''); // 초기화
        setProEndTime('');

        try {
            const response = await fetch('https://api.goding.us/redeemCoupon', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ couponCode: coupon, handle: handle }),
            });

            const data = await response.json();

            if (!data.success) {
                setMessage(data.message); // 실패 시 메시지 출력
            } else {
                // proEndTime 날짜 포맷 변환
                const date = new Date(data.proEndTime);
                const formattedTime = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${date.getHours()}시 ${date.getMinutes()}분 ${date.getSeconds()}초`;
                setProEndTime(formattedTime);
                setMessage('쿠폰이 성공적으로 등록되었습니다!');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('서버와의 통신 중 오류가 발생했습니다.');
        }
    };

    // 클립보드 내용 붙여넣기
    const pasteFromClipboard = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setCoupon(clipboardText);
        } catch (error) {
            console.error('클립보드 붙여넣기 실패:', error);
            setMessage('클립보드 접근이 허용되지 않았습니다.');
        }
    };

    return (
        <>
            <Helmet>
                <title>코드 교환 | 갓잉</title>
            </Helmet>
            <Element name="redeem" className="p-4">
                <div className="min-h-screen justify-center items-center flex">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-primary mb-4 mt-12">코드 교환</h1>
                        <p className="text-lg text-gray-500 mb-8 px-4">갓잉 쿠폰 코드를 이 곳에서 등록할 수 있습니다.</p>
                        <form className="flex flex-col items-center" onSubmit={handleSubmit}>
                            <div className="flex items-center w-96 mb-4">
                                <input
                                    type="text"
                                    name="coupon"
                                    placeholder="쿠폰 코드"
                                    value={coupon}
                                    onChange={(e) => setCoupon(e.target.value)}
                                    className="flex-grow p-4 border-2 border-gray-200 rounded-l-md h-full"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={pasteFromClipboard}
                                    className="p-4 bg-gray-200 rounded-r-md hover:bg-gray-300 transition duration-300 h-full flex items-center justify-center"
                                    style={{ minWidth: '50px' }} // 버튼 너비 조정
                                >
                                    📋
                                </button>
                            </div>

                            <input
                                type="text"
                                name="handle"
                                placeholder="핸들 (갓잉 아이디)"
                                value={handle}
                                onChange={(e) => setHandle(e.target.value)}
                                className="w-96 p-4 mb-4 border-2 border-gray-200 rounded-md"
                                required
                            />
                            <button
                                type="submit"
                                className="w-96 p-4 bg-primary text-white font-bold text-xl rounded-md hover:bg-blue-800 transition duration-300"
                            >
                                교환하기
                            </button>
                        </form>

                        {/* 메시지 출력 */}
                        {message && <p className="text-lg text-red-500 mt-4">{message}</p>}

                        {/* 성공 시 Pro 만료 시간 표시 */}
                        {proEndTime && (
                            <p className="text-lg text-primary mt-4">
                                Pro 혜택 만료 시간: <strong>{proEndTime}</strong>
                            </p>
                        )}
                    </div>
                </div>
            </Element>
        </>
    );
};

export default Redeem;
