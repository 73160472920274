import { Link } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import companyLogo from './logo.svg';
import companyLogoTrans from './logo_trans.svg';
const Home = () => {
    document.title="갓잉";
    return (
        <>
            <Element name="home">
                <div className="h-screen justify-center items-center bg-white flex">
                    <div className="grid md:grid-cols-2 md:gap-16">
                        <div className="flex justify-center items-center mb-4 md:mb-0 ml-0 md:ml-8 xl:ml-0 xs:mb-8">
                            <div className="text-center md:text-left">
                                <span className="text-6xl font-bold text-primary mb-2">갓잉<img src={companyLogoTrans} className='inline-block w-24 ml-2 pb-1' /></span>
                                <p className="text-xl md:text-2xl text-gray-500 mb-4">공유 중심의 공부 계획 및 기록 플랫폼</p>
                                <div className="space-x-4 mt-4">
                                    <Link to="/BI" className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">더 알아보기</Link>
                                    <ScrollLink to="contact" smooth={true} duration={500} className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">문의하기</ScrollLink>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center pt-8 md:pt-0">
                            <div className="relative">
                                <img src={companyLogo} alt="Company Logo" className="h-32 md:h-64 transition duration-300 transform hover:scale-105 shadow-xl rounded-3xl hover:shadow-2xl" />
                                {/* <div className="absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-lightmain bg-opacity-30 rounded-3xl">
                                    <a href="https://play.google.com/store/apps/details?id=com.example.app" target="_blank" rel="noreferrer">
                                        <button className="bg-white text-gray-800 font-bold py-2 px-4 rounded-md hover:bg-gray-100 transition duration-300"> <i className="bi bi-google-play"></i> </button>
                                    </a>
                                    <a href="https://apps.apple.com/app/id123456789" target="_blank" rel="noreferrer">
                                        <button className="bg-white text-gray-800 font-bold py-2 px-4 rounded-md hover:bg-gray-100 transition duration-300"> <i className="bi bi-apple"></i> </button>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            <Element name="contact" className="p-4 bg-ultralightmain">
                <div className="h-screen justify-center items-center flex">
                    <div className="text-center">
                        <h1 className="text-6xl font-bold text-primary mb-2">Contact</h1>
                        <p className="text-lg md:text-2xl text-gray-500 mb-8">문의사항이 있으시면 언제든지 연락주세요.</p>
                        <div className="space-x-4 mt-4">
                            <a href="mailto:admin@goding.us" className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">
                                <i className="bi bi-envelope mr-2"></i>이메일</a>
                            <a href="https://www.instagram.com/goding.us/" target="_blank" rel="noreferrer" className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">
                                <i className="bi bi-instagram mr-2"></i>인스타그램</a>
                            <a href="https://pf.kakao.com/_bxkmxaG/chat" target="_blank" rel="noreferrer" className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">
                                <i className="bi bi-chat-dots-fill mr-2"></i>카카오톡 채널</a>
                            <Link to="/support" className="text-primary border-2 border-primary rounded-md px-4 py-2 hover:bg-primary hover:text-white transition duration-300">
                                <i className="bi bi-chat-right-dots mr-2"></i>문의</Link>

                        </div>
                    </div>
                </div>
            </Element>
        </>
    );
}

export default Home;