import { Helmet } from 'react-helmet';
const About = () => {
    return (
        <>
            <Helmet>
                <title>곧 만나요! | 갓잉</title>
            </Helmet>
            <div className="h-screen justify-center items-center bg-white flex">
                <div className="text-center">
                    <h1 className="text-6xl font-bold text-primary mb-2">Coming Soon!</h1>
                    <p className="text-lg md:text-2xl text-gray-500 mb-8">곧 갓잉을 만나보실 수 있습니다! 기대해 주세요!</p>
                </div>
            </div>
        </>
    );
}

export default About;