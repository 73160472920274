import companyLogoWhite from './logo_white.png';
import { Link } from 'react-router-dom';
const footer = () => {
    return (
        <footer className="bg-primary">
            <div className="mx-auto w-full max-w-screen-xl p-8 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <Link to="/" className="flex items-center">
                            <img src={companyLogoWhite} alt="Company Logo" className="h-6 mb-8 mt-4 md:mb-0 md:mt-0" />
                        </Link>
                        <p className="text-slate-100 font-medium mt-4">공유 중심의 공부 계획 및 기록 플랫폼</p>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 pr-4">
                        <div>
                            <h2 className="mb-6 text-sm font-extrabold text-white uppercase">Resources</h2>
                            <ul className="text-slate-100 font-medium">
                                <li className="mb-4">
                                    <a href="/" className="hover:underline">갓잉</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/BI" className="hover:underline">BI</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/about" className="hover:underline">About</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-extrabold text-white uppercase">Follow us</h2>
                            <ul className="text-slate-100 font-medium">
                                <li className="mb-4">
                                    <a href="https://github.com/godingteam" className="hover:underline ">Github</a>
                                </li>
                                <li className="mb-4">
                                    <a href="https://instagram.com/goding.us" className="hover:underline">Instagram</a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/company/goding" className="hover:underline">LinkedIn</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-extrabold text-white uppercase">Legal</h2>
                            <ul className="text-slate-100 font-medium">
                                <li className="mb-4">
                                    <a href="/notice" className="hover:underline">공지사항</a>
                                </li>
                                <li className="mb-4">
                                    <a href="/privacy" className="hover:underline">개인정보 처리방침</a>
                                </li>
                                <li>
                                    <a href="/tos" className="hover:underline">이용 약관</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-slate-100 sm:text-center">© 2024 <Link to="/" className="hover:underline">Goding</Link>. All Rights Reserved.
                    </span>
                    <div className="flex mt-4 sm:justify-center sm:mt-0 mr-4">
                        <a href="https://www.instagram.com/goding.us/" target="_blank" rel="noreferrer" className="text-slate-100 hover:text-lightmain transition duration-300">
                            <i className="bi bi-instagram"></i>
                        </a>
                        <a href="https://pf.kakao.com/_bxkmxaG/chat" target="_blank" rel="noreferrer" className="text-slate-100 hover:text-lightmain transition duration-300 ms-4">
                            <i className="bi bi-chat-dots"></i>
                        </a>
                        <a href="https://github.com/godingteam" target="_blank" rel="noreferrer" className="text-slate-100 hover:text-lightmain transition duration-300 ms-4">
                            <i className="bi bi-github"></i>
                        </a>
                        <a href="https://linkedin.com/company/goding" target="_blank" rel="noreferrer" className="text-slate-100 hover:text-lightmain transition duration-300 ms-4">
                            <i className="bi bi-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default footer;