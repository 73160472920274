import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ko';
moment.locale('ko');

const Notices = () => {
    const [notices, setNotices] = useState([]);
    useEffect(() => {
        document.title = "공지사항 | 갓잉";
        fetch('https://api.goding.us/noticeList')
            .then(response => response.json())
            .then(data => setNotices(data.result))
            .catch(error => console.error('Error fetching notices:', error));
    }, []);
    return (
        <div className="px-4 md:px-20 pt-16 md:pt-20 mb-16 md:mb-20 mb-16 md:mb-20">
            <h1 className='text-4xl font-bold text-primary mb-8'>공지사항</h1>
            <ul>
                {notices.length === 0 ? (
                    <p className='text-gray-600'>공지사항을 불러오고 있습니다...</p>
                ) : (
                    notices.map(notice => (
                        <li key={notice.id}>
                            <Link to={`/notice/${notice.id}`}>
                                <h3 className="font-semibold text-2xl text-primary mb-2">{notice.title}</h3>
                                <p className="text-gray-800 text-lg">{notice.description}</p>
                                <p className="text-gray-500">
                                    {notice.content.length > 150 ? notice.content.substring(0, 150) + '...' : notice.content}
                                </p>
                                <div className='mb-4'>
                                    <span className="text-gray-500 text-sm">{moment(notice.timestamp).fromNow()}</span>
                                    <span className="text-gray-500 text-sm"> • {notice.writer}</span>
                                </div>
                            </Link>
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
}

export default Notices;