const OPL = () => {
    return (
        <div className="px-4 md:px-20 pt-16 md:pt-20 mb-16 md:mb-20">
            <h1 className="text-4xl font-bold text-primary mb-4">오픈소스 패키지 라이선스</h1>
            <p className="text-lg mb-8 text-slate-600">갓잉은 다음과 같은 오픈소스 라이브러리를 사용하고 있으며, 각각의 라이선스는 다음과 같습니다.</p>
            <h2 className="text-2xl font-bold text-primary mb-4">1. React</h2>
            <p className="text-lg mb-8 text-slate-600">React는 MIT 라이선스를 따릅니다.</p>
            <h2 className="text-2xl font-bold text-primary mb-4">2. Tailwind CSS</h2>
            <p className="text-lg mb-8 text-slate-600">Tailwind CSS는 MIT 라이선스를 따릅니다.</p>
            <h2 className="text-2xl font-bold text-primary mb-4">3. Pageclip</h2>
            <p className="text-lg mb-8 text-slate-600">Pageclip은 MIT 라이선스를 따릅니다.</p>
            <h2 className="text-2xl font-bold text-primary mb-4">4. React Scroll</h2>
            <p className="text-lg mb-8 text-slate-600">React Scroll은 MIT 라이선스를 따릅니다.</p>
        </div>
    );
}

export default OPL;