import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import moment from 'moment';
import companyLogo from './logo_trans.svg';
import 'moment/locale/ko';
moment.locale('ko');
const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notices, setNotices] = useState([]);

    useEffect(() => {
        // Fetch data from the API
        fetch('https://api.goding.us/noticeList')
            .then(response => response.json())
            .then(data => setNotices(data.result))
            .catch(error => console.error('Error fetching notices:', error));
    }, []);

    console.log(notices);

    return (
        <nav className='p-4' style={{ position: 'fixed', width: '100%', top: 0, zIndex: 1000 }}>
            <div className="flex align-middle">
                <Link to="/" className="text-white text-xl font-semibold hover:text-primary transition duration-300"><img src={companyLogo} alt="Company Logo" className="inline-block h-8 w-8 mr-2" />
                    <span className="text-xl font-semibold text-primary">갓잉</span></Link>
                <div className="flex-grow"></div>
                <div className="relative">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <i className="bi bi-bell text-xl text-secondary mr-3"></i> : <i className="bi bi-bell-fill text-xl text-primary mr-3"></i>}
                    </button>
                    {isOpen && (
                        <div className="absolute bg-secondary w-80 shadow-lg rounded-lg p-4 top-10 right-0">
                            <div className="absolute w-6 h-4 bg-secondary right-2 top-0 transform rotate-45"></div>
                            {notices.length != 0 && (
                            <ul>
                                {notices.map(notice => (
                                    <li key={notice.id}>
                                        <Link to={`/notice/${notice.id}`} className="text-white">
                                            <h3 className="font-semibold text-lg text-white">{notice.title}</h3>
                                            <p className="text-white">{notice.description}</p>
                                            <p className="text-white text-sm">{moment(notice.timestamp).fromNow()}</p>
                                            <hr className="my-2 border-white" />
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            ) || (
                                <div className="text-white">
                                    <h3 className="font-semibold text-lg text-white">공지사항을 불러오고 있습니다...</h3>
                                    <p className="text-white">잠시만 기다려주세요.</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default Nav;
